export const APP_ROUTES = Object.freeze({
    home: '/',
    login: '/login',
    authError: '/login/auth-error',
    onboard: '/onboard',
});

export const ONBOARD_STATUS = Object.freeze({
    failed: 'FAILED',
    onboarded: 'ON_BOARDED',
    notRegistered: 'NOT_REGISTERED',
    registered: 'REGISTERED',
});

export const ONBOARDED_STATUS = [ONBOARD_STATUS.onboarded, ONBOARD_STATUS.registered];
