import { LocalesMapper } from '@braincube/i18n';

function getBrowserLocale() {
    let locale = navigator.language;
    if (locale.includes('fr')) {
        locale = LocalesMapper.LOCALES.FR;
    }

    return locale;
}

export default getBrowserLocale;
