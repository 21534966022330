import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React from 'react';
import { Chip, Divider, Fab, styled } from '@mui/material';

const StyledSectionChip = styled(Chip)({
    textTransform: 'uppercase',
    fontWeight: 500,
});

const StyledDivider = styled(Divider)({
    position: 'relative',
});

const StyledCollapseButton = styled(Fab)({
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
});

function SectionTitle({ label, color, collapsed, onCollapse, ...rest }) {
    return (
        <StyledDivider {...rest}>
            <StyledSectionChip label={label} color={color} />
            <StyledCollapseButton size="small" color="default" onClick={onCollapse}>
                {!collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </StyledCollapseButton>
        </StyledDivider>
    );
}

SectionTitle.propTypes = {
    color: PropTypes.string.isRequired,
    collapsed: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onCollapse: PropTypes.func.isRequired,
};

export default SectionTitle;
