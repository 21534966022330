import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { useI18n } from '@braincube/i18n';
import { useIotServers } from '@braincube/header';
import { hasWriteAccess } from 'services';

function NoApps() {
    const i18n = useI18n();
    const { access } = useIotServers();

    const hasAccessToAppStore = useMemo(() => hasWriteAccess(access), [access]);

    return (
        <>
            <Typography align="center" variant="h4" data-testid="noAppTitle">
                {i18n.tc('content.noAppsTitle')}
            </Typography>
            <Typography data-testid="noAppSubTitle" align="center" variant="subtitle1">
                {i18n.tc(`content.${hasAccessToAppStore ? 'noAppsAccessAppStoreSubTitle' : 'noAppsSubTitle'}`)}
            </Typography>
        </>
    );
}
export default NoApps;
