import React, { useMemo } from 'react';
import { useAppContext } from 'contexts/appContext';
import { useIotServers } from '@braincube/header';
import { buildTheme } from '@braincube/theme';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { SplashScreen } from '@braincube/ui';
import { IotServerFlat } from '@braincube/svg';
import { AppRouter } from './components';

function AppContent() {
    const { isReady } = useAppContext();
    const { themeMode } = useIotServers();

    const theme = useMemo(() => {
        return buildTheme(undefined, {
            palette: {
                mode: themeMode,
            },
        });
    }, [themeMode]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <SplashScreen
                    AppLogo={IotServerFlat}
                    appName={import.meta.env.VITE_APP_NAME}
                    appVersion={import.meta.env.VITE_APP_VERSION}
                    isContentLoaded={isReady}
                    minDisplayTimeMs={1000}
                >
                    <AppRouter />
                </SplashScreen>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default AppContent;
