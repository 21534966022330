import React, { useCallback } from 'react';
import { Fab, Stack, ThemeProvider } from '@mui/material';
import { Form, Formik } from 'formik';
import { useI18n } from '@braincube/i18n';
import { useNotification } from '@braincube/ui';
import { useAppContext } from 'contexts/appContext';
import { getBaseUrl } from 'services';
import onboard from './services/api';
import { useFormTheme, useValidationSchema } from './hooks';
import { DomainField, LicenceCodeField, NameField } from './components';

const initialValues = {
    domain: '',
    name: '',
    licenceCode: '',
};

function goToAdminApp() {
    const adminAppUrl = `${getBaseUrl()}/local-commands/iot-administration/`;
    window.open(adminAppUrl, '_blank');
}

function OnboardForm() {
    const i18n = useI18n();
    const theme = useFormTheme();
    const validationSchema = useValidationSchema();
    const { enqueueError } = useNotification();
    const { onboardDatas } = useAppContext();

    const submit = useCallback(
        ({ domain, name, licenceCode }) => {
            onboard({
                licenceCode,
                name,
                domain,
            })
                .then(({ productId }) => {
                    const boardingPage = `https://${domain}/onboarding?productId=${productId}&boxId=${licenceCode}`;
                    window.open(boardingPage, '_blank');
                })
                .catch(() => enqueueError(i18n.tc('onboarding.notistack.error')));
        },
        [enqueueError, i18n]
    );

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
            {({ isValid }) => (
                <Form>
                    <ThemeProvider theme={theme}>
                        <Stack direction="column" spacing={2}>
                            <DomainField />
                            <NameField />
                            <LicenceCodeField />

                            <Stack direction="column" alignItems="center" spacing={2}>
                                {onboardDatas?.hasNetworkConfiguration && (
                                    <div>
                                        <Fab
                                            variant="extended"
                                            size="medium"
                                            color="secondary"
                                            data-testid="onboarding-networkConfiguration"
                                            onClick={goToAdminApp}
                                        >
                                            {i18n.tc('onboarding.actions.networkConfiguration')}
                                        </Fab>
                                    </div>
                                )}
                                <div>
                                    <Fab
                                        variant="extended"
                                        size="medium"
                                        color="primary"
                                        type="submit"
                                        disabled={!isValid}
                                        data-testid="onboarding-submit"
                                    >
                                        {i18n.tc('common.submit')}
                                    </Fab>
                                </div>
                            </Stack>
                        </Stack>
                    </ThemeProvider>
                </Form>
            )}
        </Formik>
    );
}

export default OnboardForm;
