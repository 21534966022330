import React, { useEffect } from 'react';
import cookie from 'js-cookie';
import { Fab, Typography } from '@mui/material';
import { useI18n } from '@braincube/i18n';
import { goToLoginStrategy } from 'services';
import { LoginAndOnboardingWrapper } from 'components';

function Login() {
    const i18n = useI18n();

    useEffect(() => {
        const autoLoginCookie = cookie.get('IoTLoginAuto');

        /**
         * Prevent auto login in development mode to prevent infinite loop (allowed for Cypress tests)
         * Auth-Token is stored on the non local domain so local is view as not logged in
         */
        if (autoLoginCookie === '' || (autoLoginCookie === 'true' && (!import.meta.env.DEV || window.Cypress))) {
            goToLoginStrategy();
        }
    }, []);

    return (
        <LoginAndOnboardingWrapper title={i18n.tc('login.title')} subtitle={i18n.tc('login.subtitle')}>
            <Typography component="div" textAlign="center">
                <Fab variant="extended" color="primary" size="medium" onClick={goToLoginStrategy}>
                    {i18n.tc('login.actionButton')}
                </Fab>
            </Typography>
        </LoginAndOnboardingWrapper>
    );
}

export default Login;
