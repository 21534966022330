import React from 'react';
import { Routes as RouterRoutes, Route, useLocation } from 'react-router-dom';
import { AuthError, Home, Login, Onboard } from 'scenes';
import { APP_ROUTES } from 'services';
import { useAppContext } from 'contexts/appContext';
import { ErrorPage404 } from 'components';
import Tracker from '@braincube/tracker';
import { ProtectedRoute } from './components';

/**
 * App router which use a <ProtectedRoute /> component to dispatch user to the great route depending on criteria :
 * - IoT server not onboarded : redirection to onboarding page
 * - IoT server boarded BUT user not logged in : redirection to login page
 * - IoT server boarded AND user logged in : redirection to home page
 */
function AppRouter() {
    const { isOnboarded, isLoggedIn } = useAppContext();

    const location = useLocation();
    Tracker.matomo.usePageView(location);

    return (
        <RouterRoutes>
            <Route
                path={APP_ROUTES.onboard}
                element={
                    <ProtectedRoute isForbidden={isOnboarded} redirectPath={APP_ROUTES.home}>
                        <Onboard />
                    </ProtectedRoute>
                }
            />
            <Route
                path={APP_ROUTES.home}
                element={
                    <ProtectedRoute
                        isForbidden={!isOnboarded || !isLoggedIn}
                        redirectPath={!isOnboarded ? APP_ROUTES.onboard : APP_ROUTES.login}
                    >
                        <Home />
                    </ProtectedRoute>
                }
            />
            <Route
                path={APP_ROUTES.login}
                element={
                    <ProtectedRoute isForbidden={!isOnboarded} redirectPath={APP_ROUTES.onboard}>
                        <Login />
                    </ProtectedRoute>
                }
            />
            <Route
                path={APP_ROUTES.authError}
                element={
                    <ProtectedRoute isForbidden={!isOnboarded} redirectPath={APP_ROUTES.onboard}>
                        <AuthError />
                    </ProtectedRoute>
                }
            />
            <Route path="*" element={<ErrorPage404 />} />
        </RouterRoutes>
    );
}

export default AppRouter;
