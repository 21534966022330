import { useMemo } from 'react';
import { buildTheme } from '@braincube/theme';
import { useTheme } from '@mui/material';

function useFormTheme() {
    const currentTheme = useTheme();

    return useMemo(
        () =>
            buildTheme(undefined, {
                components: {
                    MuiTextField: {
                        styleOverrides: {
                            root: {
                                '& label': {
                                    '&, &.MuiInputLabel-shrink': {
                                        color: currentTheme.palette.grey[400],
                                    },
                                    '&.Mui-focused': {
                                        color: currentTheme.palette.common.white,
                                    },
                                    '&.Mui-error': {
                                        color: currentTheme.palette.error.main,
                                    },
                                },
                                '& input': {
                                    color: currentTheme.palette.common.white,
                                },
                                '& .MuiInputBase-root': {
                                    '& fieldset': {
                                        borderColor: currentTheme.palette.grey[400],
                                    },
                                    '&:hover, &.Mui-focused': {
                                        '& fieldset': {
                                            borderColor: currentTheme.palette.common.white,
                                        },
                                    },
                                    '&.Mui-error': {
                                        '& fieldset': {
                                            borderColor: currentTheme.palette.error.main,
                                        },
                                    },
                                },
                            },
                        },
                    },
                    MuiFab: {
                        styleOverrides: {
                            root: {
                                '&.Mui-disabled': {
                                    color: currentTheme.palette.grey[800],
                                    backgroundColor: currentTheme.palette.grey[600],
                                },
                            },
                        },
                    },
                },
            }),
        [currentTheme.palette.common.white, currentTheme.palette.error.main, currentTheme.palette.grey]
    );
}

export default useFormTheme;
