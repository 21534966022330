import React from 'react';
import { Grid, Typography, Card, CardActionArea, CardContent, styled } from '@mui/material';
import * as importedLogos from '@braincube/svg';

const StyledCard = styled(Card)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 192,
});

const StyledCardActionArea = styled(CardActionArea)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
});

const StyledLogoWrapper = styled('div')(({ theme }) => ({
    height: theme.spacing(12),
    '& > svg': {
        fontSize: '96px',
        borderRadius: '15px',
        margin: 'auto',
        display: 'block',
    },
}));

const StyledTypographyWrapper = styled('div')({
    flex: 1,
    paddingTop: '1em',
    display: 'flex',
    alignItems: 'center',
});

const StyledTypography = styled(Typography)(({ theme }) => ({
    lineHeight: theme.spacing(3),
    width: '100%',
}));

const StyledCardContent = styled(CardContent)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

// eslint-disable-next-line react/prop-types
function Appshortcut({ icon = 'Node', name = 'Node', href = './', subPath = '..' } = {}) {
    const Logo = importedLogos[`${icon}Flat`] || importedLogos[icon] || importedLogos.Node;

    const endName = name;
    let endHref = href;
    const expectedPrefix = '/edge';
    if (endHref.startsWith(expectedPrefix)) {
        endHref = `${subPath}${endHref.substring(expectedPrefix.length)}`;
    }

    return (
        <Grid item xs={6} sm={4} md={2} lg={2} data-testid="appshortcut">
            <StyledCard elevation={4}>
                <StyledCardActionArea href={endHref} target="_blank">
                    <StyledCardContent>
                        <StyledLogoWrapper>
                            <Logo />
                        </StyledLogoWrapper>
                        <StyledTypographyWrapper>
                            <StyledTypography variant="h6" textAlign="center">
                                {endName}
                            </StyledTypography>
                        </StyledTypographyWrapper>
                    </StyledCardContent>
                </StyledCardActionArea>
            </StyledCard>
        </Grid>
    );
}

export default Appshortcut;
