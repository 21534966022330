import { object, string } from 'yup';
import { useI18n } from '@braincube/i18n';

function useValidationSchema() {
    const i18n = useI18n();

    return object({
        domain: string().required(i18n.tc('yup.required')),
        name: string().required(i18n.tc('yup.required')),
        licenceCode: string()
            .required(i18n.tc('yup.required'))
            .matches(/^[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{2}$/, i18n.tc('yup.licenceCode')),
    });
}

export default useValidationSchema;
