import React from 'react';
import { IotServer } from '@braincube/svg';
import {
    Header,
    HeaderIoTServerLogo,
    HeaderApplicationDescriptor,
    HeaderActions,
    HeaderIoTServerChooser,
    IoTHeaderMenu,
} from '@braincube/header';
import { styled, ThemeProvider } from '@mui/material';
import { getBaseUrl } from 'services';
import { buildTheme } from '@braincube/theme';

const StyledHeaderLogoWrapper = styled('div')(({ theme }) => ({
    '& > div': {
        overflow: 'hidden',
        backgroundColor: theme.palette.primary.main,
    },
}));

const baseUrl = getBaseUrl();

const theme = buildTheme(undefined, {
    palette: {
        mode: 'light',
    },
});

function AppHeader() {
    return (
        // Header is not dark theme compliant, so we wrap this with a light theme
        <ThemeProvider theme={theme}>
            <Header>
                <StyledHeaderLogoWrapper>
                    <HeaderIoTServerLogo baseUrl={baseUrl} />
                </StyledHeaderLogoWrapper>
                <HeaderIoTServerChooser />
                <HeaderApplicationDescriptor
                    title={import.meta.env.VITE_APP_NAME}
                    version={import.meta.env.VITE_APP_VERSION}
                    logo={IotServer}
                />
                <HeaderActions>
                    <IoTHeaderMenu />
                </HeaderActions>
            </Header>
        </ThemeProvider>
    );
}

export default AppHeader;
