import { getBaseUrl } from 'services/helpers';

function onboard({ licenceCode, name, domain }) {
    return fetch(`${getBaseUrl()}/edge-auth/register?license=${licenceCode}&productName=${name}&domain=${domain}`, {
        method: 'POST',
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return Promise.reject(response);
        })
        .catch((response) => Promise.reject(response));
}

export default onboard;
