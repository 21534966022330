import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { APP_ROUTES } from 'services';
import React from 'react';

function ProtectedRoute({ isForbidden, redirectPath, children }) {
    if (isForbidden) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
}

ProtectedRoute.propTypes = {
    isForbidden: PropTypes.bool.isRequired,
    redirectPath: PropTypes.oneOf(Object.values(APP_ROUTES)).isRequired,
};

export default ProtectedRoute;
