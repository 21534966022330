import { useI18n } from '@braincube/i18n';
import { useFormikContext } from 'formik';
import { TextField } from '@mui/material';
import React from 'react';

function LicenceCodeField() {
    const i18n = useI18n();

    const {
        values: { licenceCode },
        handleChange,
        errors,
    } = useFormikContext();

    return (
        <TextField
            name="licenceCode"
            data-testid="onboarding-licenceCode"
            label={i18n.tc('onboarding.fields.licenceCode.label')}
            value={licenceCode}
            onChange={handleChange}
            error={!!errors.licenceCode}
            helperText={errors.licenceCode}
            fullWidth
        />
    );
}

export default LicenceCodeField;
