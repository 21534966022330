import React from 'react';
import { Stack, styled, Typography } from '@mui/material';
import { IotServer, LogoBraincubeColor } from '@braincube/svg';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { getBranding } from 'services/api';
import { useMatch } from 'react-router-dom';
import { APP_ROUTES } from 'services';

const GradientWrapper = styled('div')(({ theme }) => ({
    border: `${theme.spacing(5)} solid ${theme.palette.common.black}`,
    minHeight: '100vh',
    backgroundImage: `radial-gradient(circle at top right, #013a58, ${theme.palette.common.black})`,
    padding: theme.spacing(15, 0), // to have same minimal gradient height around BlackCard
}));

const BlackCard = styled('div')(({ theme }) => ({
    margin: '0 auto',
    position: 'relative',
    padding: `30px ${theme.spacing(2)}`,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    width: 300,
}));

const BlackCardContent = styled(Stack)(({ theme }) => ({
    borderTop: `1px dotted ${theme.palette.common.white}`,
    borderBottom: `1px dotted ${theme.palette.common.white}`,
    padding: theme.spacing(2, 0),
}));

const LogoWrapper = styled('div')({
    display: 'block',
    width: 100,
    height: 100,
    position: 'absolute',
    top: -70,
    left: 0,
    right: 0,
    margin: '0 auto',
    '& > img, & > svg': {
        display: 'block',
        width: '100%',
        height: '100%',
    },
});

const StyledLogoBraincubeColor = styled(LogoBraincubeColor)(({ theme }) => ({
    fill: theme.palette.common.black,
}));

const StyledIoTServerLogo = styled(IotServer)({
    width: 100,
    height: 100,
    display: 'block',
    margin: '0 auto',
});

const FullWidthContainer = styled('div')({
    width: '100%',
});

function LoginAndOnboardingWrapper({ noContentLogo, title, subtitle, children }) {
    const isOnboardingPage = !!useMatch(APP_ROUTES.onboard);

    /**
     * This query is not usefully on the onboarding page because branding can't be called during onboarding process
     */
    const { data: branding } = useQuery({
        queryKey: ['iot-branding'],
        queryFn: getBranding,
        enabled: !isOnboardingPage,
        retry: false,
    });

    return (
        <GradientWrapper>
            <BlackCard>
                <LogoWrapper>{branding?.logo ? <img src={branding.logo} alt="" /> : <StyledLogoBraincubeColor />}</LogoWrapper>
                <Typography
                    variant="h6"
                    textTransform="uppercase"
                    textAlign="center"
                    pt={1}
                    pb={1}
                    sx={{ letterSpacing: '0.25em' }}
                >
                    {title}
                    {!!subtitle && (
                        <>
                            <br />
                            {subtitle}
                        </>
                    )}
                </Typography>
                <BlackCardContent alignItems="center" spacing={3}>
                    {!noContentLogo && <StyledIoTServerLogo />}
                    <FullWidthContainer>{children}</FullWidthContainer>
                </BlackCardContent>
            </BlackCard>
        </GradientWrapper>
    );
}

LoginAndOnboardingWrapper.propTypes = {
    noContentLogo: PropTypes.bool,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
};

LoginAndOnboardingWrapper.defaultProps = {
    noContentLogo: false,
    subtitle: '',
};

export default LoginAndOnboardingWrapper;
