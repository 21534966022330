import React from 'react';
import { useI18n } from '@braincube/i18n';
import { LoginAndOnboardingWrapper } from 'components';
import { OnboardForm } from './components';

function Onboard() {
    const i18n = useI18n();

    return (
        <LoginAndOnboardingWrapper title={i18n.tc('onboarding.title')} subtitle={i18n.tc('onboarding.subtitle')}>
            <OnboardForm />
        </LoginAndOnboardingWrapper>
    );
}

export default Onboard;
