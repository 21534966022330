import { Button, styled } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorPage } from '@braincube/ui';
import { useI18n } from '@braincube/i18n';

const ErrorPageWrapper = styled('div')({
    minHeight: '100vh',
});

function ErrorPage404() {
    const i18n = useI18n();
    const navigate = useNavigate();
    const goToHomepage = useCallback(() => navigate('/'), [navigate]);

    return (
        <ErrorPageWrapper>
            <ErrorPage title={i18n.tc('common.errors.notFound')} subtitle={i18n.tc('common.errors.invalidUrl')}>
                <Button variant="contained" color="primary" onClick={goToHomepage}>
                    {i18n.tc('common.goToHomepageButton')}
                </Button>
            </ErrorPage>
        </ErrorPageWrapper>
    );
}

export default ErrorPage404;
