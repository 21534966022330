import React from 'react';
import { createRoot } from 'react-dom/client';

import { checkOnboarded } from 'services/api';

import ContextualizedApp from './App';

const head = document.getElementsByTagName('head')[0];
const script = document.createElement('script');

script.async = false;
script.type = 'text/javascript';

function init() {
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(<ContextualizedApp />);
}

async function configureScript() {
    script.src = `https://cdn.mybraincube.com/configuration.js?timestamp=${Date.now()}`;
    try {
        const status = await checkOnboarded();
        if (status?.domain) {
            script.src = `https://cdn.${status.domain}/configuration.js?timestamp=${Date.now()}`;
        }
    } catch (error) {
        console.error('Error when getting the IoT status', error);
    } finally {
        script.onload = function render() {
            init();
        };

        script.onerror = function render() {
            init();
        };

        head.appendChild(script);
    }
}

configureScript();
