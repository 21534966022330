import React from 'react';
import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useI18n } from '@braincube/i18n';

function DomainField() {
    const i18n = useI18n();

    const {
        values: { domain },
        handleChange,
        errors,
    } = useFormikContext();

    return (
        <TextField
            name="domain"
            data-testid="onboarding-domain"
            label={i18n.tc('onboarding.fields.domain.label')}
            value={domain}
            onChange={handleChange}
            error={!!errors.domain}
            helperText={errors.domain}
            fullWidth
        />
    );
}

export default DomainField;
