import { Card, CardContent, Grid, Skeleton, Stack, styled } from '@mui/material';
import React from 'react';

const StyledSkeletonCard = styled(Card)({
    height: 192,
});

const StyledSkeletonText = styled(Skeleton)({
    width: '100%',
});

function AppShortcutSkeleton(props) {
    return (
        <Grid item xs={6} sm={4} md={2} lg={2} {...props} data-testid="appshortcut-skeleton">
            <StyledSkeletonCard elevation={4}>
                <CardContent>
                    <Stack direction="column" spacing={3} alignItems="center" justifyContent="space-between">
                        <Skeleton animation="wave" variant="rectangular" width={96} height={96} />
                        <StyledSkeletonText animation="wave" variant="text" />
                    </Stack>
                </CardContent>
            </StyledSkeletonCard>
        </Grid>
    );
}

function AppShortcutSkeletonList() {
    // eslint-disable-next-line react/no-array-index-key
    return [...Array(12)].map((x, i) => <AppShortcutSkeleton key={`skeleton-${i}`} />);
}

export default AppShortcutSkeletonList;
