import React, { createContext, useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useIotServers } from '@braincube/header';
import { checkOnboarded } from 'services/api';
import { ONBOARDED_STATUS } from 'services';

const AppContext = createContext({});

export function AppContextProvider({ children }) {
    const { email, isReady: isLoadingMeEnded } = useIotServers();

    /**
     * Email is set (from the header getIoTMe() function) only if user is logged in
     * So, we use this value to check if user in logged in
     * @type {boolean}
     */
    const isLoggedIn = useMemo(() => !!email, [email]);

    /**
     * Check if this IoT is onboarded
     */
    const { data: onboardDatas, isLoading: isLoadingOnboard } = useQuery({
        queryKey: ['iot-onboarded'],
        queryFn: checkOnboarded,
        retry: false,
    });

    /**
     * Check the IoT onboard status
     * @type {boolean}
     */
    const isOnboarded = useMemo(() => ONBOARDED_STATUS.includes(onboardDatas?.onboardStatus), [onboardDatas?.onboardStatus]);

    /**
     * The isReady const is used to hide the SplashScreen to give access to the great scene
     * During the required queries, the SplashScreen stay displayed
     * @type {boolean}
     */
    const isReady = useMemo(() => !isLoadingOnboard && isLoadingMeEnded, [isLoadingMeEnded, isLoadingOnboard]);

    const values = useMemo(
        () => ({
            isReady,
            isOnboarded,
            isLoggedIn,
            onboardDatas,
        }),
        [isReady, isOnboarded, isLoggedIn, onboardDatas]
    );

    return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
}

export const useAppContext = () => useContext(AppContext);
