/**
 * @description Compute the base path
 * @returns {string}
 */
export function getBasePath() {
    const { pathname } = window.location;
    if (pathname.includes('/home/')) {
        return `${pathname.split('/home/')[0]}/home/`;
    }
    return '/';
}

/**
 * @description Compute the base url
 * @returns {string}
 */
export function getBaseUrl() {
    return window.location.href.split('/edge-auth/')[0];
}

/**
 * @description Get path to /edge/
 * @param {string} [uid]
 * @returns {string}
 */
export function pathToEdgeInstance(uid) {
    if (!uid) {
        return `${getBaseUrl()}/edge`;
    }
    return `${getBaseUrl()}/${uid}/edge`;
}

/**
 * @description Inject path to /edge/ into obj.basePath
 * @param {string} [uid]
 * @param {object} obj
 */
export function injectPathToResponse(uid, obj) {
    const output = obj;
    output.basePath = getBaseUrl(uid);
    output.edgePath = pathToEdgeInstance(uid);
    return output;
}
