import { getBaseUrl } from 'services';

const basePath = getBaseUrl();

// eslint-disable-next-line import/prefer-default-export
export function checkOnboarded() {
    return fetch(`${basePath}/edge-auth/status`, {
        method: 'GET',
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return Promise.reject(response);
        })
        .catch((response) => Promise.reject(response));
}

export function getBranding() {
    return fetch(`${basePath}/edge-auth/api/branding`, {
        method: 'GET',
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return Promise.reject(response);
        })
        .catch((response) => Promise.reject(response));
}
