import { useI18n } from '@braincube/i18n';
import { useFormikContext } from 'formik';
import { TextField } from '@mui/material';
import React from 'react';

function NameField() {
    const i18n = useI18n();

    const {
        values: { name },
        handleChange,
        errors,
    } = useFormikContext();

    return (
        <TextField
            name="name"
            data-testid="onboarding-name"
            label={i18n.tc('onboarding.fields.name.label')}
            value={name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
            fullWidth
        />
    );
}

export default NameField;
