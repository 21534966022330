import React, { useMemo } from 'react';
import { useI18n } from '@braincube/i18n';
import { Fab, Link, Stack, Typography } from '@mui/material';
import { useAppContext } from 'contexts/appContext';
import { goToLoginStrategy } from 'services';
import { LoginAndOnboardingWrapper } from 'components';

function AuthError() {
    const i18n = useI18n();
    const { onboardDatas } = useAppContext();

    const todoUrl = useMemo(() => {
        if (onboardDatas?.domain) {
            return `https://cdn.${onboardDatas?.domain}/support`;
        }

        return '';
    }, [onboardDatas?.domain]);

    return (
        <LoginAndOnboardingWrapper noContentLogo title={i18n.tc('login.authError.title')}>
            <Typography variant="body2" paragraph textAlign="center">
                {i18n.tc('login.authError.content')}
            </Typography>

            <Stack flexDirection="column" alignItems="center" spacing={4}>
                {todoUrl && (
                    <div>
                        <Fab variant="extended" color="primary" size="medium" component={Link} href={todoUrl}>
                            {i18n.tc('common.contactSupport')}
                        </Fab>
                    </div>
                )}
                <div>
                    <Fab variant="extended" color="secondary" size="medium" onClick={goToLoginStrategy}>
                        {i18n.tc('common.retry')}
                    </Fab>
                </div>
            </Stack>
        </LoginAndOnboardingWrapper>
    );
}

export default AuthError;
