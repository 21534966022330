import { getBaseUrl } from 'services/helpers/paths';

export function compare(a, b) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

export function goToLoginStrategy() {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectParam = urlParams.get('redirect') || `${getBaseUrl()}/edge-auth/home/`;

    window.location.replace(`${getBaseUrl()}/edge-auth/b3-auth/strategy?redirect=${redirectParam}`);
}
