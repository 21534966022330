const GRANTS = {
    IPLWADMIN: 'IPLWADMIN',
    ADMIN: 'ADMIN',
    EXPERT: 'EXPERT',
    COACH: 'COACH',
    MANAGER: 'MANAGER',
    CUSTOM: 'CUSTOM',
    USER: 'USER',
};

export function hasAdminAccess(level) {
    return [GRANTS.ADMIN, GRANTS.IPLWADMIN].includes(level);
}

export function hasWriteAccess(level) {
    return [GRANTS.EXPERT, GRANTS.ADMIN, GRANTS.IPLWADMIN].includes(level);
}
